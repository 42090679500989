<template>
  <div class="registered m">
    <TopBar :type="2" :pageTitle="regType==1?'个人注册':'企业注册'"/>
    <div class="reg-content" :class="regType==2?'enterprise':''">
      <div class="from-box">
        <div class="input-box enterprise-name" v-if="regType==2">
          <input type="text" class="input-text" placeholder="请输入企业名称">
        </div>
        <div class="tip-err" v-if="regType==2">当前企业信息已存在</div>
        <div class="input-box user-name">
          <input type="text" class="input-text" placeholder="请输入联系人姓名" v-model="regData.username">
        </div>
        <div class="tip-err" v-if="nameErr">{{nameErr}}</div>
        <div class="input-box user-phone">
          <input type="number" maxlength="11" class="input-text" placeholder="请输入联系人电话号码" v-model="regData.phone">
        </div>
        <div class="tip-err" v-if="phoneErr">{{phoneErr}}</div>
        <div class="input-box ver-code">
          <input type="text" class="input-text" placeholder="请输入验证码" v-model="regData.code">
          <span class="get-ver-code" :class="this.timer?'get':''" @click="getCode">{{getCodeBtnText}}</span>
        </div>
        <div class="tip-err" v-if="codeErr">{{codeErr}}</div>
        <template  v-if="siteCont.id==99 || siteCont.id==106 && regType == 1">
          <div class="input-box danwei">
            <input
              type="text"
              class="input-text"
              placeholder="请输入单位"
              v-model="regData.remark"
            />
          </div>
          <div v-if="siteCont.id==106 && regType == 1" class="input-box zhiwu">
            <input
              type="text"
              class="input-text"
              placeholder="请输入职务"
              v-model="regData.duty"
            />
          </div>
        </template>
        <div class="input-box password">
          <input :type="inputType" class="input-text" placeholder="请输入密码" v-model="regData.password">
          <div class="show-pass" :class="inputType=='text'?'open':'close'" @click="showPass(1)"></div>
        </div>
        <div class="input-box password">
          <input :type="inputType2" class="input-text" placeholder="请再次输入密码" v-model="regData.confirm_password">
          <div class="show-pass" :class="inputType2=='text'?'open':'close'" @click="showPass(2)"></div>
        </div>
        <div class="tip-err" v-if="passErr">{{passErr}}</div>
        <button class="submit-btn" :class="!btnType?'disabled':''" @click="regBtn">提交注册</button>
        <div class="agreement">
          <label><input type="checkbox" v-model="agreeCheck"> 我已阅读并同意</label><router-link class="agreement-link" to='/'>隐私政策</router-link>和<router-link to='/' class="agreement-link">《用户协议》</router-link>
        </div>
      </div>
      <!-- <div class="switch-reg-type" @click="switchType" v-if="regType!=0">{{regType==1 ? '我要注册企业账户？' : '我要注册个人账户？'}}</div> -->
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
import { mapMutations } from "vuex";
export default {
  name: 'Rgistered',
  data(){
    return {
      timer: '',              //倒计时
      getCodeBtnText: '获取验证码',
      agreeCheck: true,       //是否同意协议
      regType: 0,             //1：个人注册；2企业注册；
      inputType: 'password',  //密码框类型
      inputType2: 'password', //再次输入密码框类型
      regData: {              //注册信息
        username: '',         //用户名
        password: '',         //密码
        confirm_password: '', //再次输入密码
        phone: '',            //手机号
        code: '',              //短信验证码
        duty:'',//职务
      },
      phoneErr: '',           //手机号错误提示
      nameErr: '',            //姓名错误提示
      passErr: '',            //密码错误提示
      btnType: false,         //按钮是否可用
      nonNull: false,         //非空验证
      codeErr: '',            //短信验证提示
      siteCont:{},
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.regType = this.$route.params.type;
    if(localStorage.getItem('siteInfo')){
      this.siteCont = JSON.parse(localStorage.getItem('siteInfo'))
    }
  },
  methods: {
    ...mapMutations(['ShowTipModal']), //tip弹窗
    //切换注册类型
    switchType() {
      this.regType = (this.regType==1 ? '2' : '1');
    },
    //显示密码
    showPass(type){
      if(type == 1){
        this.inputType = this.inputType=='text'?'password':'text'
      }else{
        this.inputType2 = this.inputType2=='text'?'password':'text'
      }
    },
    //手机号验证
    phoneValidation(){
      if(this.regData.phone){
        let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

        if(!reg.test(this.regData.phone)){
          this.phoneErr = '请填写正确的手机号'
          this.btnType = false;
        }else{
          this.phoneErr = '';
          
          if(this.nonNull==true && this.passErr=='' && this.phoneErr==''){
            this.btnType = true;
          }
        }
      }
    },
    //注册按钮
    regBtn(){
      if(this.agreeCheck){
        //个人注册
        if(this.regType == 1){
          if(this.siteCont.id==99 || this.siteCont.id==106){
            if(!this.regData.remark){
              this.ShowTipModal({
                text: '请输入单位',     //提示弹窗内容
              })
              return
            }
            if(this.siteCont.id == 106) {
              if(!this.regData.duty){
              this.ShowTipModal({
                text: '请输入职务',     //提示弹窗内容
              })
              return
            }
            }
          }
          this.$axios.post(`/v1/login/userRegister`, this.regData).then(res => {
            if(res.code == 0){
              this.ShowTipModal({
                text: '注册成功',     //提示弹窗内容
                fun: function(){     //回调
                  localStorage.setItem('needLogin', true);
                  this.$router.push('/index');
                }
              })
            }else{
              //this.passErr = res.msg
              this.ShowTipModal({
                text: res.msg,     //提示弹窗内容
              })
            }
          })
        }
      }else{
        this.ShowTipModal({
          text: '请勾选《相关条款》',     //提示弹窗内容
          showBtns: true,               //是否显示提示弹窗按钮
        })
      }
    },
    //表单验证
    formValidation(newName){
      if( newName.username == '' || newName.phone == '' || newName.password == '' || newName.confirm_password == '' || newName.code == '' ){
        this.nonNull = false;
        this.btnType = false;
        return;
      }

      this.nonNull = true; //非空验证成功  

      if(this.passErr=='' && this.phoneErr==''){
        this.btnType = true;
      }
    },
    //密码验证
    pssValidation(){
      if((this.regData.password!='' && this.regData.confirm_password!='') && (this.regData.password != this.regData.confirm_password)){
        this.btnType = false;
        this.passErr = '两次密码不一致';
      }else{
        this.passErr = '';

        if(this.nonNull==true && this.passErr=='' && this.phoneErr==''){
          this.btnType = true;
        }
      }
    },
    getCode(){
      if(this.regData.phone){
        this.$axios.post(`/v1/login/sendSmsCode`, {
          phone: this.regData.phone
        }).then(res => {
          this.codeErr = res.msg;

          if(res.code==0){
            let time = 60;
            
            this.getCodeBtnText = time + '秒后再获取'
            this.timer = setInterval(()=>{
              time --;
              if(time == 0){
                this.getCodeBtnText = '获取验证码';
                clearInterval(this.timer);
                this.timer = '';
              }else{
                this.getCodeBtnText = time+'秒后再获取';
              }
            }, 1000);
          }
        })
      }else{
        this.phoneErr = '请填写手机号码'
      }
    }
  },
  watch:{
    //监听表单
    regData: {
      handler(newName,oldName){
          //表单验证
          this.formValidation(newName,oldName);
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
    'regData.confirm_password' :{
      handler(newName,oldName){
          //密码验证
          this.pssValidation(newName,oldName);
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
    'regData.password' :{
      handler(newName,oldName){
          //密码验证
          this.pssValidation(newName,oldName);
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
    'regData.phone' :{
      handler(newPhone,oldPhone){
          if(newPhone.length>=11){
            //密码验证
            this.phoneValidation(newPhone,oldPhone);
          }
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    }
  }
}
</script>
<style scoped lang="scss">
.registered.m{
  .reg-content{
    padding: 88px 48px 0;
  }
  .input-box{
    padding-left: 116px;
    border: 2px solid #E5E5E5;
    border-radius: 6px;
    margin-bottom: 24px;
    background-size: auto 50px;
    background-position: 19px center;
    &::after{
      width: 1px;
      height: 28px;
      left: 90px;
      margin-top: -14px;
    }
    &.ver-code{
      padding-right: 170px;
    }
  }
  .input-text{
    padding: 25px 0;
    font-size: 28px;
    color: #333;
    &::placeholder{
      font-size: 28px;
      color: #999;
    }
  }
  .get-ver-code{
    font-size: 28px;
  }
  .show-pass{
    width: 50px;
    height: 50px;
    right: 24px;
    top: 50%;
    margin-top: -25px;
  }
  .tip-err{
    font-size: 24px;
  }
}

.submit-btn{
  width: 100%;
  height: 88px;
  background: rgba(37, 78, 212, 0.3);
  border-radius: 6px;
  font-size: 28px;
  margin-top: 44px;
  cursor: pointer;
  background-color: #254ED4;
  color: #fff;
  &.disabled{
    background: rgba(37, 78, 212, 0.3);
    color: #FFF;
  }
}
.agreement{
    display: flex;
    align-items: center;
    padding-top: 14px;
    font-size: 24px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input{
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .agreement-link{
      color: #254ED4;
    }
    label{
      display: flex;
      align-items: center;
    }
  }
  .switch-reg-type{
    margin-top: 44px;
    font-size: 12px;
    color: #666;
    text-align: center;
    cursor: pointer;
  }
  .user-name{
    padding-top: 0;
  }
</style>